var curSub = null;
var curNav = null;

$(document).ready(function(){
    curSub = $("#submenu .visible");
    curNav = $("#menu .active");

    $( "figure img" ).load(function() {
      setGallery();
    });
    $(window).on('resize', function(){
      setGallery();
    });

})

function setGallery(){

    var maxWidth = 0;
    $("#gallery .overview").children().each(function(){
        maxWidth += $(this).width()+5;
    });
    $("#gallery .overview").width(maxWidth);
    $("#gallery").tinyscrollbar({axis: 'x'});

}

function showSub(id){
    if(curSub != null)
        curSub.hide();

    curSub = $(".sub"+id);
    curSub.show();

    curNav = $("#nav"+id);

}
